
import { defineComponent } from 'vue';
import { client } from '@/client';
import { ElMessage } from 'element-plus';

export default defineComponent({
  name: "friendlink-add",
  data: () => {
    return {
      params: {
        title: "",
        link: "",
        sort: 0,
        createdAt: new Date(),
      },

      paramsRules: {
        //校验规则
        name: [
          { required: true, message: "请输入标签名称", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "名称长度在 2 到 20 个字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    handleAttr(e: any) {
      console.log("e-->", e);
    },

    handleSubCid(e: any) {
      console.log("e-->", e);
    },

    //新增
    async create() {
        let res = await client.callApi("friendlink/Create",this.params);
        if (res.isSucc) {
          ElMessage({
            message: "新增成功^_^",
            type: "success",
          });
          this.$router.go(-1);
        }else{
          ElMessage({
            message: res.err.message,
            type: "error",
          });
        }
    },

    submit(formName: string|number) {
      let ref:any = this.$refs[formName];
      ref.validate((valid: any) => {
        if (valid) {
          this.create();
        } else {
          return false;
        }
      });
    },
  },
});
